import React from 'react'
import MainLayout from '../../components/MainLayout'
import NewsItem from '../../components/NewsItem'
import { get } from 'lodash'

const Cmp = ({ data }) => {
  const props = get(data, 'wordpressPost', {})

  const description = props.acf.meta_description
  const image = props.acf.meta_image?.localFile?.url

  const metaData = {
    title: props?.acf.meta_title,
    keywords: props.acf.keywords,
    description: description,
    image: image,
  }

  return (
    <MainLayout topSpacing pageId={data?.wordpressPost?.wordpress_id} metaData={metaData}>
      <NewsItem {...props} />
    </MainLayout>
  )
}

export default Cmp
